@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/mont/Poppins-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: Poppins-bold;
  src: url(./assets/fonts/mont/Poppins-Bold.ttf);
  font-display: swap;
}
@font-face {
  font-family: Poppins-light;
  src: url(./assets/fonts/mont/Poppins-Light.ttf);
  font-display: swap;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important ;
}

code {
  font-family: Poppins-bold, source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.2em;

  color: #3345da;
  margin: 0;
}

h2 {
  margin: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
}
.gradientText {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;
  background: linear-gradient(to right, #a04d9a 0%, #3345da 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.no_view {
  opacity: 0;
  /* display: none; */
}

.si_view {
  /* opacity: 0; */
  /* display: flex !important; */
}

select {
  border: none;
  border-bottom: 2px solid #000000;
  padding: 44px 0;
  width: 100%;

  font-size: 16px;

  color: #232323;
  border-radius: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;

  background: url("./assets/images/arrow_down.svg") no-repeat 95%;
}

@media (max-width: 575.98px) {
  h2 {
    font-size: 30px !important;
  }
}

@media (max-width: 767.98px) {
  h2 {
    font-size: 40px;
  }

  h4 {
    font-size: 18px;
  }

  select {
    padding: 20px 0;
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}
