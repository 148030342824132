.App {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*:focus {
  /* border: solid red 1px; */
  outline: none;
}

@keyframes button-hover {
  0% {
    width: 56px;
    left: -10px;
    right: inherit;
  }
  25% {
    width: calc(100% + 20px);
  }
  50% {
    width: 56px;
    right: -10px;
    left: inherit;
  }
  75% {
    width: calc(100% + 20px);
  }
  100% {
    width: 56px;
    left: -10px;
    right: inherit;
  }
}

.fade-in-slow {
  animation: fadeIn 0.8s forwards ease-in;
}

.fade-in-fast {
  animation: fadeIn 0.4s forwards ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* * {
  border: solid red 1px;
} */
